<template>
  <a id='a' v-bind:href='mailto'></a>
</template>

<script>
  export default{
    name: 'OpenMailClient',
    props: {
      collection: {
        type: Object,
        required: true
      }
    },
    data: () =>({
      email: null,
      selected: null,
      mailto: null,
    }),
    created(){
      this.selected = this.collection.master.selected;

      if (!this.selected) {
        jet.msg({
          text:'Необходимо выбрать запись!',
          color:'warning'
        });
        return;
      }
      
      this.email = this.selected.vcautocarrierOrgidEmail
      if(!this.email){
        jet.msg({
          text:'Необходимо указать электронную почту!',
          color:'warning'
        });
        return;
      }

      this.mailto = `mailto:${this.email}`;
    },
    mounted(){
      this.clickLink();
    },
    methods:{
      clickLink(){
        $('#a')[0].click();
      }
    }
  }
</script>